<template>
  <a-config-provider :locale="locale">
    <a-layout class="layout">
      <a-layout-header :style="{ padding: '0' }">
        <div class="header-container">
          <MenuLogo />
          <div
            v-if="$store.state.account.loginStatus && $store.state.account.networkStatus"
            class="setting-button"
            @click="$router.push('/menu')"
            @keydown="$router.push('/menu')"
          >
            <MenuOutlined :style="{ fontSize: '20px' }" />
          </div>
        </div>
      </a-layout-header>
      <div v-if="status.isLoading" class="loading">
        <a-spin :indicator="indicator" />
      </div>
      <div v-else class="content-container">
        <router-view />
      </div>
    </a-layout>
  </a-config-provider>
</template>

<script>
import {
  defineComponent, getCurrentInstance, h, reactive,
} from 'vue';
import MenuLogo from '@/components/project/MenuLogo.vue';
import { MenuOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { checkAuthToken, checkUserExist, getControllerNetworkStatus } from '@/network/http';
import { useCookie } from 'vue-cookie-next';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import zhCN from 'ant-design-vue/es/locale/zh_CN';

export default defineComponent({
  components: {
    MenuLogo,
    MenuOutlined,
  },
  setup() {
    const currentInstance = getCurrentInstance();
    const globalFunctions = currentInstance.appContext.config.globalProperties.$globalFunctions;
    const cookie = useCookie();
    const store = useStore();
    const router = useRouter();
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '24px',
      },
      spin: true,
    });
    const status = reactive({
      isLoading: true,
    });
    if (cookie.isCookieAvailable('token')) {
      const token = cookie.getCookie('token');
      checkAuthToken(
        globalFunctions.generateRequestHeader(token),
      ).then((response) => {
        globalFunctions.handleAPIResponse(
          response,
          () => {
            store.commit('account/setToken', token);
            getControllerNetworkStatus(
              globalFunctions.generateRequestHeader(token),
            )
              .then((networkStatusResponse) => {
                globalFunctions.handleAPIResponse(
                  networkStatusResponse,
                  (content) => {
                    if (content.isOnline) {
                      store.commit('account/setLoginStatus', true);
                      store.commit('account/setInitializeStatus', true);
                      store.commit('account/setNetworkStatus', true);
                      status.isLoading = false;
                    } else {
                      store.commit('account/setLoginStatus', true);
                      store.commit('account/setInitializeStatus', true);
                      store.commit('account/setNetworkStatus', false);
                      router.push('/offline');
                      status.isLoading = false;
                    }
                  },
                );
              })
              .catch((error) => {
                globalFunctions.notify({ content: '请求失败' });
                // eslint-disable-next-line no-console
                console.log(error);
              });
          },
          () => {
            cookie.removeCookie('token');
            store.commit('account/setLoginStatus', false);
            store.commit('account/setInitializeStatus', true);
            router.push('/login');
            status.isLoading = false;
          },
        );
      }).catch((error) => {
        globalFunctions.notify({ content: '请求失败' });
        // eslint-disable-next-line no-console
        console.log(error);
      });
    } else {
      checkUserExist().then((response) => {
        globalFunctions.handleAPIResponse(
          response,
          () => {
            store.commit('account/setLoginStatus', false);
            store.commit('account/setInitializeStatus', true);
            router.push('/login');
            status.isLoading = false;
          },
          () => {
            store.commit('account/setLoginStatus', false);
            store.commit('account/setInitializeStatus', true);
            router.push('/register');
            status.isLoading = false;
          },
        );
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      });
    }
    return {
      indicator,
      status,
      locale: zhCN,
    };
  },
});
</script>

<style lang="scss">
@import "assets/styles/index";

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

[data-theme='dark'] .site-layout-content {
  background: #141414;
}

.header-container {
  padding: 0 25px;
}

@media screen and (min-width: 1000px) {
  .header-container {
    width: 1000px;
    margin: 0 auto;
  }

  .content-container {
    width: 1000px;
    margin: 0 auto;
  }
}

.setting-button {
  color: white;
  float: right;
  line-height: initial;
  margin-top: 22px;
  cursor: pointer;
}
</style>
