import { ERROR_CODE_DEFINITION, MESSAGE_CONFIG } from '@/utilities/constants';
import { message } from 'ant-design-vue';
import CamelUnderlineConversion from '@/utilities/CamelUnderlineConversion';

import moment from 'moment-timezone';

export function notify(payload, type) {
  message.config(MESSAGE_CONFIG);
  switch (type) {
    case 'success':
      message.success(payload);
      break;
    case 'error':
      message.error(payload);
      break;
    case 'info':
      message.info(payload);
      break;
    case 'warning':
      message.warning(payload);
      break;
    default:
      message.info(payload);
  }
}

export function generateRequestHeader(token) {
  return { 'Auth-Token': token };
}

export function handleAPIResponse(rawResponse, success, failure) {
  const camelUnderlineConversion = new CamelUnderlineConversion(rawResponse);
  const response = camelUnderlineConversion.underlineToCamel();
  if (response.data.isSuccess === true) {
    if (response.data.content !== undefined) {
      success(response.data.content);
    } else {
      notify({ content: '未知错误' });
    }
  } else if (failure) {
    failure();
  } else {
    let notice;
    if (ERROR_CODE_DEFINITION[response.data.errorCode]) {
      notice = ERROR_CODE_DEFINITION[response.data.errorCode];
    } else {
      notice = (response.data.errorCode !== undefined && response.data.errorCode !== null)
        ? response.data.errorCode
        : '未知错误';
    }
    notify({ content: notice });
  }
}

export function convertTimestampToDateString(
  timestamp,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  hasDate = true,
  hasTime = true,
) {
  const time = moment(timestamp * 1000).tz(timezone);
  let dateString = '';
  if (hasDate) {
    dateString += time.format('YYYY/MM/DD');
  }
  if (hasTime) {
    if (hasDate) {
      dateString += ' ';
    }
    dateString += time.format('HH:mm:ss');
  }
  return dateString;
}

export function isNumber(string) {
  const regPos = /^\d+(\.\d+)?$/;
  const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/;
  return regPos.test(string) || regNeg.test(string);
}
