<template>
  <a-layout-content>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="定时任务"
    >
      <template #extra>
        <a-button
          type="primary"
          @click="$router.push('/task-setting')"
        >
          新建任务
        </a-button>
      </template>
    </a-page-header>
    <div v-if="status.isLoading" class="loading">
      <a-spin :indicator="indicator" />
    </div>
    <a-table
      v-else
      :columns="columns"
      :data-source="taskList"
      :scroll="tableHeight"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <span v-if="record.isSuspend" class="be-suspend">{{ record.name }}</span>
          <span v-else>{{ record.name }}</span>
        </template>
        <template v-else-if="column.key === 'time'">
          <span v-if="record.isSuspend" class="be-suspend">{{ record.time }}</span>
          <span v-else>{{ record.time }}</span>
        </template>
        <template v-else-if="column.key === 'action'">
          <div>
            <div class="table-action-button-container">
              <a-button
                shape="circle"
                @click="showRunOnceConfirm(record.key, record.name)"
              >
                <template #icon><CaretRightOutlined /></template>
              </a-button>
            </div>
            <div class="table-action-button-container">
              <a-button
                shape="circle"
                @click="showSuspendConfirm(record.key, record.name, record.isSuspend)"
              >
                <template #icon>
                  <StopOutlined v-if="record.isSuspend" class="be-suspend" />
                  <StopOutlined v-else />
                </template>
              </a-button>
            </div>
            <div class="table-action-button-container">
              <a-button
                shape="circle"
                @click="$router.push('/task-setting/' + record.key)"
              >
                <template #icon><SettingOutlined /></template>
              </a-button>
            </div>
          </div>
        </template>
      </template>
    </a-table>
  </a-layout-content>
</template>

<script>
import {
  defineComponent, getCurrentInstance, onMounted, reactive, h, ref, createVNode,
} from 'vue';
import {
  LoadingOutlined, SettingOutlined, StopOutlined, CaretRightOutlined, ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import { getTaskList, manualRunDevice, suspendTask } from '@/network/http';
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex';

const tableOffset = 192;
const actionColumnWidth = {
  min: 66,
  max: 134,
};
const columns = reactive([
  {
    title: '任务名称',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: '执行时间',
    key: 'time',
    dataIndex: 'time',
    width: 120,
  },
  {
    title: '操作',
    key: 'action',
    align: 'center',
    width: actionColumnWidth.min,
  },
]);

export default defineComponent({
  name: 'HomeView',
  components: {
    SettingOutlined,
    StopOutlined,
    CaretRightOutlined,
  },
  setup() {
    const currentInstance = getCurrentInstance();
    const globalFunctions = currentInstance.appContext.config.globalProperties.$globalFunctions;
    const store = useStore();
    const taskList = reactive([]);
    const tableHeight = ref({ x: '100%', y: document.documentElement.clientHeight - tableOffset });
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '24px',
      },
      spin: true,
    });
    if (document.documentElement.clientWidth >= 560) {
      columns[columns.length - 1].width = actionColumnWidth.max;
    }
    const status = reactive({
      isLoading: true,
    });
    onMounted(() => {
      if (store.state.account.token !== null) {
        getTaskList(
          globalFunctions.generateRequestHeader(store.state.account.token),
        )
          .then((response) => {
            globalFunctions.handleAPIResponse(
              response,
              (content) => {
                content.forEach((item) => {
                  taskList.push({
                    key: item.id,
                    name: item.name,
                    time: item.time,
                    isSuspend: item.isSuspend,
                  });
                });
                status.isLoading = false;
              },
            );
          })
          .catch((error) => {
            globalFunctions.notify({ content: '请求失败' });
            // eslint-disable-next-line no-console
            console.log(error);
          });
      }
      window.onresize = () => {
        tableHeight.value.y = document.documentElement.clientHeight - tableOffset;
        if (document.documentElement.clientWidth >= 560) {
          columns[columns.length - 1].width = actionColumnWidth.max;
        } else {
          columns[columns.length - 1].width = actionColumnWidth.min;
        }
      };
    });
    const showRunOnceConfirm = (id, taskName) => {
      Modal.confirm({
        title: `确定立即执行任务“${taskName}”？`,
        icon: createVNode(ExclamationCircleOutlined),
        // content: 'Some descriptions',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          manualRunDevice(
            globalFunctions.generateRequestHeader(store.state.account.token),
            { taskId: id },
          ).then((response) => {
            globalFunctions.handleAPIResponse(
              response,
              () => {
              },
            );
          }).catch((error) => {
            globalFunctions.notify({ content: '请求失败' });
            // eslint-disable-next-line no-console
            console.log(error);
          });
        },
        onCancel() {},
      });
    };
    const showSuspendConfirm = (id, taskName, suspendStatus) => {
      Modal.confirm({
        title: suspendStatus ? `确定启用任务“${taskName}”？` : `确定禁用任务“${taskName}”？`,
        icon: createVNode(ExclamationCircleOutlined),
        // content: 'Some descriptions',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          suspendTask(
            globalFunctions.generateRequestHeader(store.state.account.token),
            { id, isSuspend: !suspendStatus },
          ).then((response) => {
            globalFunctions.handleAPIResponse(
              response,
              (content) => {
                taskList.forEach((item, index) => {
                  if (item.key === content.id) {
                    taskList[index].isSuspend = content.isSuspend;
                  }
                });
              },
            );
          }).catch((error) => {
            globalFunctions.notify({ content: '请求失败' });
            // eslint-disable-next-line no-console
            console.log(error);
          });
        },
        onCancel() {},
      });
    };
    return {
      columns,
      taskList,
      tableHeight,
      indicator,
      status,
      showRunOnceConfirm,
      showSuspendConfirm,
    };
  },
});
</script>

<style lang="scss" scoped>
.ant-table-body {
  max-height: v-bind(tableHeight) !important;
}

.ant-table-body-inner {
  max-height: v-bind(tableHeight) !important;
}

.be-suspend {
  color: crimson;
}
</style>
