import { createStore } from 'vuex';

const account = {
  namespaced: true,
  state: () => ({
    initialized: false,
    loginStatus: false,
    networkStatus: false,
    token: null,
    id: null,
  }),
  mutations: {
    setInitializeStatus(state, status) {
      state.initialized = status;
    },
    setLoginStatus(state, status) {
      state.loginStatus = status;
    },
    setNetworkStatus(state, status) {
      state.networkStatus = status;
    },
    setToken(state, token) {
      state.token = token;
    },
    setID(state, id) {
      state.id = id;
    },
  },
};

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account,
  },
});
