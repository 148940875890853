import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import('@/views/MenuView.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/RegisterView.vue'),
  },
  {
    path: '/login-setting',
    name: 'LoginSetting',
    component: () => import('@/views/setting/LoginSettingView.vue'),
  },
  {
    path: '/device-setting',
    name: 'DeviceSetting',
    component: () => import('@/views/setting/DeviceSettingView.vue'),
  },
  {
    path: '/device-setting/detail/:code?',
    name: 'DeviceSettingDetail',
    component: () => import('@/views/setting/DeviceSettingDetailView.vue'),
  },
  {
    path: '/task-setting/:code?',
    name: 'TaskSetting',
    component: () => import('@/views/TaskSettingView.vue'),
  },
  {
    path: '/controller-status',
    name: 'ControllerStatus',
    component: () => import('@/views/misc/ControllerStatusView.vue'),
  },
  {
    path: '/task-log',
    name: 'TaskLog',
    component: () => import('@/views/misc/TaskLogView.vue'),
  },
  {
    path: '/location-setting',
    name: 'LocationSetting',
    component: () => import('@/views/setting/LocationSettingView.vue'),
  },
  {
    path: '/offline',
    name: 'Offline',
    component: () => import('@/views/OfflineView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
