export const IS_SERVER = true;

export const API_URL = IS_SERVER
  ? 'https://server.intelligent-farm.palpitation.moe'
  : 'http://api.v2.intelligent-farm.palpitation.moe';

export const ERROR_CODE_DEFINITION = {
  UNSUPPORTED_REQUEST_METHOD: '尚未支持该请求',
  UNDEFINED_FUNCTION: '该请求方法尚未定义',
  UNDEFINED_ACTION: '该请求方法尚未定义',
  MISSING_PARAMETER_FUNCTION: '缺少请求方法名称',
  SERVER_EXCEPTION: '服务异常',
  MISSING_PARAMETERS: '缺少请求参数',
  SET_CLIENT_FD_FAILURE: '客户端设置失败',
  UNDEFINED_ACCESS_TOKEN: '未定义的访问令牌',
  INVALID_ACCESS_TOKEN: '无效的访问令牌',
  NO_ACCESS_PERMISSION: '没有访问权限',
  INVALID_CONTENT: '请求内容无效',
  TOKEN_VALIDATION_FAILURE: '访问令牌校验失败',

  PARAMETER_LENGTH_EXCEEDING: '参数值长度过长',
  PARAMETER_LENGTH_DEFICIENT: '参数值长度不足',
  INCORRECT_PARAMETER_TYPE: '不正确的参数类型',
  PARAMETER_VALUE_LESS_THAN_MIN_VALUE: '参数值小于最小值',
  PARAMETER_VALUE_GREATER_THAN_MAX_VALUE: '参数值大于最大值',
  INVALID_TIME_FORMAT: '无效的时间格式',
  INVALID_REPEAT_FORMAT: '无效的重复格式',
  INVALID_ID: '无效的ID',
  INVALID_TIMEZONE: '无效的时区',
  INVALID_TWILIGHT_TYPE: '无效的晨昏类型',
  NO_LOCATION_SETTING: '没有设置位置信息',

  SAME_PIN_NUMBER: '针脚数相同',
  PIN_NUMBER_ALREADY_IN_USE: '该针脚已被使用',
  UNDEFINED_DEVICE: '未定义的设备',
  INVALID_DEVICE_TYPE: '无效的设备类型',
  DEVICE_ALREADY_EXISTS: '设备已存在',
  CANNOT_CHANGE_DEVICE_TYPE_WHEN_DEVICE_ASSOCIATED_WITH_TASK: '该设备已被分配任务，无法改变该设备类型',
  CANNOT_DELETE_DEVICE_WHEN_DEVICE_ASSOCIATED_WITH_TASK: '该设备已被分配任务，无法删除该设备',

  INVALID_TASK_TYPE: '无效的任务类型',
  TASK_TYPE_MUST_BE_CONSISTENT: '任务类型不一致',
  ONLY_ONE_INTERVAL_TASK_FOR_ONE_DEVICE: '一个设备只能设置一个间隔执行任务',
  TASK_CANNOT_SET_AT_SAME_TIME: '相同时间只能设置一个任务',
  DURATION_MUST_GREATER_THAN_INTERVAL: '间隔时长必须大于执行时长',

  USER_ALREADY_EXIST: '用户已经存在',
  USERNAME_OR_PASSWORD_INCORRECT: '用户名或者密码错误',
  TOO_MANY_LOGINS: '登录次数过多，请稍后再试',
  USER_DATA_UPDATE_FAILED: '用户数据更新失败',
  NOT_ALLOWED: '不允许的操作',

  NO_CLIENT: '未找到控制器',
  NO_RECORD: '未找到记录',
  PROXY_RESPONSE_FAILED: '代理响应错误',
};

export const MESSAGE_CONFIG = {
  top: '10px',
  duration: 2,
  maxCount: 3,
};
