import axios from 'axios';
import { API_URL } from '@/utilities/constants';
import CamelUnderlineConversion from '@/utilities/CamelUnderlineConversion';

function request(config) {
  const instance = axios.create({
    baseURL: API_URL,
    method: 'POST',
    timeout: 30000,
  });
  return instance(config);
}

export function checkAuthToken(headers) {
  return request({
    headers,
    data: {
      function: 'checkAuthToken',
    },
  });
}

export function login(data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    data: {
      function: 'login',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function logout(headers) {
  return request({
    headers,
    data: {
      function: 'logout',
    },
  });
}

export function checkUserExist() {
  return request({
    data: {
      function: 'checkUserExist',
    },
  });
}

export function register(data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    data: {
      function: 'register',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function changeUsernameAndPassword(headers, data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    headers,
    data: {
      function: 'changeUsernameAndPassword',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function getDeviceType(headers) {
  return request({
    headers,
    data: {
      function: 'getDeviceType',
    },
  });
}

export function getDeviceSetting(headers, data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    headers,
    data: {
      function: 'getDeviceSetting',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function saveDeviceSetting(headers, data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    headers,
    data: {
      function: 'saveDeviceSetting',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function deleteDeviceSetting(headers, data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    headers,
    data: {
      function: 'deleteDeviceSetting',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function getDeviceSettingList(headers) {
  return request({
    headers,
    data: {
      function: 'getDeviceSettingList',
    },
  });
}

export function getDeviceList(headers) {
  return request({
    headers,
    data: {
      function: 'getDeviceList',
    },
  });
}

export function getTaskSetting(headers, data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    headers,
    data: {
      function: 'getTaskSetting',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function saveTaskSetting(headers, data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    headers,
    data: {
      function: 'saveTaskSetting',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function suspendTask(headers, data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    headers,
    data: {
      function: 'suspendTask',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function deleteTask(headers, data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    headers,
    data: {
      function: 'deleteTask',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function getActionList(headers, data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    headers,
    data: {
      function: 'getActionList',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function getTaskList(headers) {
  return request({
    headers,
    data: {
      function: 'getTaskList',
    },
  });
}

export function manualRunDevice(headers, data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    headers,
    data: {
      function: 'manualRunDevice',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function getControllerStatus(headers) {
  return request({
    headers,
    data: {
      function: 'getControllerStatus',
    },
  });
}

export function getControllerRunningLog(headers, data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    headers,
    data: {
      function: 'getControllerRunningLog',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function getLocationSetting(headers) {
  return request({
    headers,
    data: {
      function: 'getLocationSetting',
    },
  });
}

export function saveLocationSetting(headers, data) {
  const camelUnderlineConversion = new CamelUnderlineConversion(data);
  return request({
    headers,
    data: {
      function: 'saveLocationSetting',
      content: camelUnderlineConversion.camelToUnderline(),
    },
  });
}

export function getControllerNetworkStatus(headers) {
  return request({
    headers,
    data: {
      function: 'getControllerNetworkStatus',
    },
  });
}
